/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {getImage, GatsbyImage} from 'gatsby-plugin-image';
function _createMdxContent(props) {
  const _components = Object.assign({
    blockquote: "blockquote",
    p: "p",
    a: "a",
    em: "em",
    h2: "h2",
    strong: "strong"
  }, _provideComponents(), props.components), {Box} = _components;
  if (!Box) _missingMdxReference("Box", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Aikaisemmat kaksi artikkelia ", React.createElement(_components.a, {
    href: "/ideat/strategiakartta-auttaa-mallintamaan"
  }, "Strategiakartoista"), " ja ", React.createElement(_components.a, {
    href: "/ideat/start-with-why"
  }, "Start with Why:sta"), " on kirjoitettu tätä kirjoitusta silmällä pitäen ja ne kannattaakin käydä vilkaisemassa, jos Start with Why (Kysy ensin miksi) tai strategiakartat ja Balanced Scorecard eivät ole entuudestaan tuttuja."), "\n"), "\n", React.createElement(_components.p, null, "Tämä idea syntyi keskusteluissa asiakkaan kanssa, kun suunnittelimme fasilitointimallia organisaation henkilöstön kanssa järjestettävään työpajaan. Halusimme keskustella yrityksen arvoista ja olemassaolon tarkoituksesta, mutta myös hyvin konkreettisesti yrityksen toiminnoista ja erottautumistekijöistä."), "\n", React.createElement(_components.p, null, "Strategiakartta on minulle hierarkia, jossa perustan pitää olla tukeva, jotta ne kannattelevat ylempien tasojen kasvun. Vaikka perustana on tasapainotettu tuloskortti, tekemisen ja tavoitteiden suuntaaminen muokkaa kuvasta luonnollisesti kartion."), "\n", React.createElement(_components.p, null, "Start with Why:n kolme kehää taas voidaan piirtää kartioksi, jonka kärjessä on kaiken ydin - Miksi. Simon Sinek käyttää itsekin tätä ja rakentaa siitä vielä mielikuvan megafonista. Megafonin ääni on Miksi ja kartio vahvistaa sanomaa."), "\n", React.createElement(_components.p, null, "Kun nämä kaksi kartiota asettaa vierekkäin, syntyy toisiaan ", React.createElement(_components.em, null, "täydentävät"), " hierarkiat. Tasot eivät ole suoraan rinnasteisia, vaan ne linkittyvät toisiinsa myös ristiin ja täydentävät toisiaan."), "\n", React.createElement(_components.h2, null, "Mikä tekee meistä erilaisen? Entä erinomaisen?"), "\n", React.createElement(Box, {
    textAlign: "center"
  }, React.createElement(GatsbyImage, {
    image: getImage(props.data.mdx.frontmatter.images[0]),
    alt: "Toisiaan täydentävät strategiakartiot"
  })), "\n", React.createElement(_components.p, null, "Kaiken perusta on ", React.createElement(_components.em, null, "Miksi"), ". Siitä syntyy organisaation merkitys. Näin se on myös organisaatiopääoman ydin, jonka ympärille yrityksen tulee rakentaa arvonsa ja kulttuurinsa."), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Miten"), " ja ", React.createElement(_components.em, null, "mitä"), " ovat yrityksen toimintatapoja, niillä tehdään asiakkaille tarjottavat tuotteet ja palvelut, ne tuottavat yrityksen arvon."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Se millä yritys erottautuu, mikä tekee siitä erinomaisen, löytyy ohjaamalla keskustelua risteilemään kartioiden välillä.")), "\n", React.createElement(_components.p, null, "Arvokartioista toinen muistuttaa minkä arvojen päälle yrityksen toiminta perustuu, toinen mitä arvoa se tuottaa."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
